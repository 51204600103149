import { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import UserInfoPersonalSales from '../Personal/MySales';
import UserInfoPaymentVouchers from '../Personal/PaymentVouchers';
import MeetingHub from './MeetingHub';
import MeetingRoom from './MeetingRoom';
import ProjectManagementAdmin from './ProjectManagement';
import UserManagementAdmin from './UserManagement';
import UserInfoEditAdmin from './UserManagement/Edit/index';

import CountryLandmarkManagement from './CountryLandmarkManagement';
import EventTraining from './EventTraining';

import MyTeam from '../Team/MyTeam';
import PermissionGroupUser from './PermissionGroupUsers';
import TeamManagement from './TeamManagement';

import AdminApproval from './AdminAdministration/Approval';

import SubsalesClaims from "./SubsalesClaimsBeta";
import SubsalesListings from "../SuperadminSubsales";
import FormManagement from "./FormManagement";
import SubsalesAccessibility from "./SubsalesAccessibility";
import InventoryChecklist from "./IndentoryChecklist";
import AdminOrderManagement from "./AdminOrderManagement";
import AdminProductManagement from "./AdminProductManagement";
import AdminCouponManagement from "./AdminCouponManagement";
import AdminNameCardManagement from "./AdminNameCardManagement";
import PersonalisedVideo from "../VideoCreator";
import VideoTemplateManagement from "./VideoTemplateManagement";
import GraphicStudioTemplate from "./GraphicStudioTemplate";
import GraphicStudio from "../GraphicStudio";
import EmailTemplates from "./EmailTemplates";
import EmailCampaigns from "../EmailCampaigns";
import TenancyAgreementManagement from "containers/Dashboard/Administrator/TenancyAgreementManagement";

import PersonalStatistic from '../Statistic';
import BranchManagement from './BranchManagement';
import ReaRenewalManagement from './ReaRenewalManagement';

import AdminTutorials from './AdminTutorial';

import Sessions from "./Sessions";
import AdminAnalyticCentre from "./AdminAnalyticCentre";
import AdminActivities from "./AdminActivities";
import AdminAmlaReport from "./AdminAmlaReport";
import CustomerDueDilleigence from "./CustomerDueDillegence";
import OTPVerification from "./OTPVerification";

import ExamIntegration from './ExamIntegration';
import ExamMngmt from './ExamMngmt';
import TrainingMngmt from './TrainingMngmt';

import LeadMngmt from '../LeadManagement';
import PresentationCentre from '../PresentationCentre';

import NewsFeedManagement from './NewNewsfeedManagement';

import AppThemeManagement from './AppThemeManagement';
import AppVersion from './AppVersion';
import MOTDManagement from './MOTDManagement';
import MOTDStatistic from './MOTDStatistic';

import CoAgency from './CoAgency';

import AdminSalesManagement from './AdminSalesManagement';

import Agreement from "./Agreement";
import QRDoorEntry from "./QRDoorEntry";
import ApiKeyManagement from "./ApiKeyManagement";
import tenancyAgreement from "./FormManagement/ConfirmationFormManagement/components/tenancyAgreement";

class AdminRouter extends Component {
  render = () => {
    return (
      <Switch>
        <Route
          exact
          path={'/dashboard/admin/user-management'}
          component={UserManagementAdmin}
        />
        <Route
          exact
          path={'/dashboard/admin/user-management/update'}
          component={UserInfoEditAdmin}
        />
        <Route
          exact
          path={'/dashboard/admin/user-management/details/sales'}
          component={UserInfoPersonalSales}
        />
        <Route
          exact
          path={'/dashboard/admin/user-management/details/my-team'}
          component={MyTeam}
        />
        <Route
          exact
          path={'/dashboard/admin/user-management/details/payment-vouchers'}
          component={UserInfoPaymentVouchers}
        />

        <Route
          exact
          path={'/dashboard/admin/project-management'}
          component={ProjectManagementAdmin}
        />

        <Route
          exact
          path={'/dashboard/admin/country-landmark-management'}
          component={CountryLandmarkManagement}
        />

        <Route
          exact
          path={'/dashboard/admin/event-training'}
          component={EventTraining}
        />
        <Route
          exact
          path={'/dashboard/admin/meeting-room'}
          component={MeetingRoom}
        />
        <Route
          exact
          path={'/dashboard/admin/meeting-hub'}
          component={MeetingHub}
        />
        <Route
          exact
          path={'/dashboard/admin/agent-approval'}
          component={AdminApproval}
        />
        <Route
          exact
          path={'/dashboard/admin/rea-renewal'}
          component={ReaRenewalManagement}
        />
        <Route
          exact
          path={'/dashboard/admin/branch-management'}
          component={BranchManagement}
        />

        <Route
          exact
          path={'/dashboard/admin/team-management'}
          component={TeamManagement}
        />

        <Route
          exact
          path={'/dashboard/superadmin/permission-group-user'}
          component={PermissionGroupUser}
        />

        <Route
          exact
          path={'/dashboard/admin/subsales-claims'}
          component={SubsalesClaims}
        />

        <Route
          exact
          path={"/dashboard/admin/tenancy-agreements"}
          component={TenancyAgreementManagement}
        />

        <Route
          exact
          path={"/dashboard/superadmin/subsales"}
          component={SubsalesListings}
        />

        <Route
          exact
          path={'/dashboard/superadmin/subsales-accessibility'}
          component={SubsalesAccessibility}
        />
        <Route
          exact
          path={'/dashboard/admin/inventory-checklist'}
          component={InventoryChecklist}
        />
        <Route path={'/dashboard/admin/forms'} component={FormManagement} />

        <Route
          exact
          path={'/dashboard/admin/order-management'}
          component={AdminOrderManagement}
        />
        <Route
          exact
          path={'/dashboard/admin/product-management'}
          component={AdminProductManagement}
        />
        <Route
          exact
          path={'/dashboard/admin/coupon-management'}
          component={AdminCouponManagement}
        />
        <Route
          exact
          path={'/dashboard/admin/namecard-management'}
          component={AdminNameCardManagement}
        />

        <Route
          exact
          path={'/dashboard/admin/users-management/details/statistic/:mobile'}
          component={PersonalStatistic}
        />

        <Route
          exact
          path={'/dashboard/admin/tutorials'}
          component={AdminTutorials}
        />

        <Route
          exact
          path={'/dashboard/admin/analytic-centre'}
          component={AdminAnalyticCentre}
        />

        <Route
          exact
          path={"/dashboard/admin/admin-activities"}
          component={AdminActivities}
        />
        <Route
          exact
          path={"/dashboard/admin/cdd"}
          component={CustomerDueDilleigence}
        />

        <Route
          exact
          path={'/dashboard/admin/amla-report'}
          component={AdminAmlaReport}
        />

        <Route exact path={'/dashboard/admin/sessions'} component={Sessions} />

        <Route
          exact
          path={'/dashboard/admin/verification'}
          component={OTPVerification}
        />

        <Route
          exact
          path={'/dashboard/admin/training-centre'}
          component={TrainingMngmt}
        />

        <Route
          exact
          path={'/dashboard/admin/exam-management'}
          component={ExamMngmt}
        />
        <Route
          exact
          path={'/dashboard/admin/exam-integration'}
          component={ExamIntegration}
        />

        <Route
          exact
          path={'/dashboard/admin/lead-management'}
          component={LeadMngmt}
        />

        <Route
          exact
          path={'/dashboard/admin/newsfeed-management'}
          component={NewsFeedManagement}
        />

        <Route
          exact
          path={'/dashboard/admin/motd-management'}
          component={MOTDManagement}
        />
        <Route
          exact
          path={'/dashboard/admin/motd-statistic'}
          component={MOTDStatistic}
        />

        <Route
          exact
          path={'/dashboard/admin/app-theme-management'}
          component={AppThemeManagement}
        />
        <Route
          exact
          path={'/dashboard/admin/app-version'}
          component={AppVersion}
        />

        <Route exact path={'/dashboard/admin/co-agency'} component={CoAgency} />

        <Route
          exact
          path={'/dashboard/admin/sale-management'}
          component={AdminSalesManagement}
        />

        <Route
          exact
          path={'/dashboard/admin/agreement-management'}
          component={Agreement}
        />
        <Route
          exact
          path={'/dashboard/admin/qr-door-entry'}
          component={QRDoorEntry}
        />
        <Route
          exact
          path={'/dashboard/admin/presentation-centre'}
          component={PresentationCentre}
        />
        <Route
          exact
          path={'/dashboard/admin/personalised-video'}
          component={PersonalisedVideo}
        />
        <Route
          exact
          path={'/dashboard/admin/video-template-management'}
          component={VideoTemplateManagement}
        />
        <Route
          exact
          path={'/dashboard/admin/graphic-studio-template'}
          component={GraphicStudioTemplate}
        />
        <Route
          exact
          path={'/dashboard/admin/graphic-studio'}
          component={GraphicStudio}
        />
        <Route
          exact
          path={'/dashboard/admin/email-templates'}
          component={EmailTemplates}
        />
        <Route
          exact
          path={'/dashboard/admin/email-campaigns'}
          component={EmailCampaigns}
        />

        <Route
          exact
          path={'/dashboard/admin/api-key-management'}
          component={ApiKeyManagement}
        />

        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/user-management'}
          component={UserManagementAdmin}
        />
        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/user-management/update'
          }
          component={UserInfoEditAdmin}
        />
        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/user-management/details/sales'
          }
          component={UserInfoPersonalSales}
        />
        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/user-management/details/my-team'
          }
          component={MyTeam}
        />
        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/user-management/details/payment-vouchers'
          }
          component={UserInfoPaymentVouchers}
        />

        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/project-management'
          }
          component={ProjectManagementAdmin}
        />

        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/country-landmark-management'
          }
          component={CountryLandmarkManagement}
        />

        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/event-training'}
          component={EventTraining}
        />
        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/meeting-room'}
          component={MeetingRoom}
        />
        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/meeting-hub'}
          component={MeetingHub}
        />
        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/agent-approval'}
          component={AdminApproval}
        />
        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/rea-renewal'}
          component={ReaRenewalManagement}
        />
        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/branch-management'
          }
          component={BranchManagement}
        />

        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/team-management'}
          component={TeamManagement}
        />

        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/superadmin/permission-group-user'
          }
          component={PermissionGroupUser}
        />

        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/subsales-claims'}
          component={SubsalesClaims}
        />

        <Route
          exact
          path={"/admin-impersonate/:profileId/dashboard/admin/tenancy-agreements"}
          component={TenancyAgreementManagement}
        />

        <Route
          exact
          path={"/admin-impersonate/:profileId/dashboard/superadmin/subsales"}
          component={SubsalesListings}
        />
        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/analytic-centre'}
          component={AdminAnalyticCentre}
        />
        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/superadmin/subsales-accessibility'
          }
          component={SubsalesAccessibility}
        />
        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/inventory-checklist'
          }
          component={InventoryChecklist}
        />
        <Route
          path={'/admin-impersonate/:profileId/dashboard/admin/forms'}
          component={FormManagement}
        />

        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/order-management'
          }
          component={AdminOrderManagement}
        />
        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/product-management'
          }
          component={AdminProductManagement}
        />
        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/coupon-management'
          }
          component={AdminCouponManagement}
        />
        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/namecard-management'
          }
          component={AdminNameCardManagement}
        />

        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/users-management/details/statistic/:mobile'
          }
          component={PersonalStatistic}
        />

        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/tutorials'}
          component={AdminTutorials}
        />

        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/sessions'}
          component={Sessions}
        />

        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/verification'}
          component={OTPVerification}
        />

        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/training-centre'}
          component={TrainingMngmt}
        />

        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/exam-management'}
          component={ExamMngmt}
        />
        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/exam-integration'
          }
          component={ExamIntegration}
        />
        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/lead-management'}
          component={LeadMngmt}
        />

        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/newsfeed-management'
          }
          component={NewsFeedManagement}
        />

        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/motd-management'}
          component={MOTDManagement}
        />
        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/motd-statistic'}
          component={MOTDStatistic}
        />

        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/app-theme-management'
          }
          component={AppThemeManagement}
        />
        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/app-version'}
          component={AppVersion}
        />

        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/co-agency'}
          component={CoAgency}
        />

        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/sale-management'}
          component={AdminSalesManagement}
        />

        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/agreement-management'
          }
          component={Agreement}
        />
        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/qr-door-entry'}
          component={QRDoorEntry}
        />
        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/presentation-centre'
          }
          component={PresentationCentre}
        />
        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/personalised-video'
          }
          component={PersonalisedVideo}
        />
        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/video-template-management'
          }
          component={VideoTemplateManagement}
        />
        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/graphic-studio-template'
          }
          component={GraphicStudioTemplate}
        />
        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/graphic-studio'}
          component={GraphicStudio}
        />
        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/email-templates'}
          component={EmailTemplates}
        />
        <Route
          exact
          path={'/admin-impersonate/:profileId/dashboard/admin/email-campaigns'}
          component={EmailCampaigns}
        />

        <Route
          exact
          path={
            '/admin-impersonate/:profileId/dashboard/admin/api-key-management'
          }
          component={ApiKeyManagement}
        />
      </Switch>
    );
  };
}

export default withRouter(AdminRouter);
