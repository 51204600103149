import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";

import { Get } from "utils/axios";
import roundUp from "utils/roundUp";
import { requestError } from "utils/requestHandler";
import { numberWithCommas } from "utils/thousandSeparator";
import { storeTeamSalesDate } from "actions/teamSalesDate";
import { storeLastView } from "actions/lastView";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      bookedUnitLoading: false,
      reassignSubmissions: {},
      searchParams: [
        {
          label: "Sales Date From",
          value: "start_date",
          type: "date",
          param: Moment()
            .year(new Date().getFullYear())
            .month(0)
            .date(1)
            .format("YYYY-MM-DD")
        },
        {
          label: "Sales Date To",
          value: "end_date",
          type: "date",
          param: new Moment().format("YYYY-MM-DD"),
        },
      ],

      title: "",
      selectedSales: null,
      view: "Project",
      subsalesType: "All",
      showSearchTips: false,
      totalData: {
        totalSales: 0,
        totalProjectSales: 0,
        totalSubsaleSales: 0,
        totalAgencyComm: 0,
      },
      showAgentSales: false,
      showAgentsWithoutSales: false,
      showSubmittedUnitOnly: "2",
      submittedSubsales: [],
      initialSubmittedSubsales: [],
      projectSales: [],
      initialProjectSales: [],
      combinedSubsales: [],
      initialcombinedSubsales: [],
    };

    load = (param) => this.setState({ loading: param })

    onChangeSalesHOC = (val, context) => this.setState({ [context]: val })

    hideFunction = (type) => {
      const filterConditions = {
        "Sale": {
          "1": (item) => item.total_agency_comm !== 0,
          "2": (item) => item.totalConvertedSubsalesPersonalSales !== 0 || item.total_agency_comm !== 0,
          "3": (item) => item.totalConvertedSubsalesPersonalSales !== 0
        },
        "Rental": {
          "1": (item) => item.total_agency_comm !== 0,
          "2": (item) => item.totalConvertedRentalPersonalSales !== 0 || item.total_agency_comm !== 0,
          "3": (item) => item.totalConvertedRentalPersonalSales !== 0
        },
        "All": {
          "1": (item) => item.total_agency_comm !== 0,
          "2": (item) => item.totalCombinedConvertedPersonalSales !== 0 || item.total_agency_comm !== 0,
          "3": (item) => item.totalAllConvertedSubsalesPersonalSales !== 0
        }
      }
      return filterConditions[type][this.state.showSubmittedUnitOnly]
    };

    calculateAgencyCom = (data,type) => {
      let temp = [];
      data.map((item) => {
        switch (type) {
          case "Sale":
            temp.push({
              ...item,
              total_agency_comm: roundUp(item.sale_total_agency_comm, 2),
              total_estimated_comm: roundUp(item.sale_total_estimated_comm, 2),
            });
            break;
          case "Rental":
            temp.push({
              ...item,
              total_agency_comm: roundUp(item.rental_total_agency_comm, 2),
              total_estimated_comm: roundUp(
                item.rental_total_estimated_comm,
                2,
              ),
            });
            break;
          default:
            temp.push({
              ...item,
              total_agency_comm: roundUp(
                item.sale_total_agency_comm + item.rental_total_agency_comm,
                2,
              ),
              total_estimated_comm: roundUp(
                item.sale_total_estimated_comm +
                  item.rental_total_estimated_comm,
                2,
              ),
            });
        }
      });
      return temp;
    }

    updateFilteredData = (type) => {
      let temp = _.cloneDeep(this.state.initialcombinedSubsales)
      temp = this.processSubsalesSubmittedSales(temp)
      let filterElement = this.hideFunction(type)
      if (this.state.showAgentsWithoutSales) { 
        temp = temp.filter(filterElement)
      }
      this.setState({ combinedSubsales: temp }, () => this.updateTotalTeamSales(temp))
    }

    onChangeSubsalesType = (val) => this.setState({ subsalesType: val }, () => this.updateFilteredData(val))
    onChangeSubsalesUnit = (val) => this.setState({ showSubmittedUnitOnly: val }, () => this.updateFilteredData(this.state.subsalesType)) 

    getTeamSales = (id, startDate, endDate) => {
      let endpoint = `/connector/members/date_summary?identity_id=${id}&start_date=${startDate}&end_date=${endDate}`;

      if (startDate === undefined) {
        endpoint = `/connector/members/date_summary?identity_id=${id}`;
      }

      Get(
        endpoint,
        this.getTeamSalesSuccess,
        this.getTeamSalesError,
        this.load,
      );
    };
    getTeamSalesSuccess = (payload) => {
      let dataCombinedSubsales = this.processCombinedSubsales(payload.combined_subsale)
      this.setState(
        {
          projectSales: payload.project,
          initialProjectSales: payload.project,
          combinedSubsales: this.processSubsalesSubmittedSales(
            dataCombinedSubsales,
          ),
          initialcombinedSubsales: dataCombinedSubsales,
          submittedSubsales: this.processSubsalesSubmittedSales(
            payload.sub_booked,
          ),
          initialSubmittedSubsales: payload.sub_booked,
          showAgentsWithoutSales: false,
        }, () => this.calculateData(),
      );
    };
    getTeamSalesError = (error) => requestError(error);

    processCombinedSubsales = (data) => {
      let temp = []
      data.map((item) => {
        temp.push({
          ...item,
          totalCombinedPersonalSales: roundUp(item.totalSubsalesPersonalSales+item.totalRentalPersonalSales,2),
          totalCombinedConvertedPersonalSales: roundUp(item.totalConvertedSubsalesPersonalSales+item.totalConvertedRentalPersonalSales,2),
          totalCombinedGroupSales: roundUp(item.totalSubsalesGroupSales+item.totalRentalGroupSales,2),
          totalCombinedConvertedGroupSales: roundUp(item.totalConvertedSubsalesGroupSales+item.totalConvertedRentalGroupSales,2),
        })
      })
      return temp
    }

    processSubsalesSubmittedSales = (data) => {
      let temp = [];
      data.map((item) => {
        switch (this.state.subsalesType) {
          case "Sale":
            temp.push({
              ...item,
              total_agency_comm: roundUp(item.sale_total_agency_comm, 2),
              total_estimated_comm: roundUp(item.sale_total_estimated_comm, 2),
            });
            break;
          case "Rental":
            temp.push({
              ...item,
              total_agency_comm: roundUp(item.rental_total_agency_comm, 2),
              total_estimated_comm: roundUp(
                item.rental_total_estimated_comm,
                2,
              ),
            });
            break;
          default:
            temp.push({
              ...item,
              total_agency_comm: roundUp(
                item.sale_total_agency_comm + item.rental_total_agency_comm,
                2,
              ),
              total_estimated_comm: roundUp(
                item.sale_total_estimated_comm +
                  item.rental_total_estimated_comm,
                2,
              ),
            });
        }
      });
      return temp;
    };

    calculateData = () => {
      let tmpTotalProjectSales = 0;
      let tmpTotalSubsaleSales = 0;
      let tmptotalAgencyComm = 0;
      this.state.combinedSubsales.map((item) => {
        tmpTotalSubsaleSales = tmpTotalSubsaleSales + item.totalAllSubsalesPersonalSales;
        tmptotalAgencyComm = tmptotalAgencyComm + item.total_agency_comm;
      });
      this.state.projectSales.map((item) => {
        tmpTotalProjectSales =
          tmpTotalProjectSales + item.totalProjectPersonalSales;
      });
      this.setState({
        totalData: {
          totalSales: numberWithCommas(
            (tmpTotalSubsaleSales + tmpTotalProjectSales).toFixed(2),
          ),
          totalProjectSales: numberWithCommas(tmpTotalProjectSales.toFixed(2)),
          totalSubsaleSales: numberWithCommas(tmpTotalSubsaleSales.toFixed(2)),
          totalAgencyComm: numberWithCommas(tmptotalAgencyComm.toFixed(2)),
        },
      });
    };

    updateTotalTeamSales = (data) => {
      let tmpTotalSubsaleSales = 0
      const tmpCombineSale = data.filter(this.hideFunction(this.state.subsalesType));
      const tmptotalAgencyComm = tmpCombineSale.reduce((total, item) => total + item.total_agency_comm, 0);
      if ( this.state.subsalesType === "Sale") {
        tmpTotalSubsaleSales = tmpCombineSale.reduce((total, item) => total + item.totalConvertedSubsalesPersonalSales, 0);
      } else if ( this.state.subsalesType === "Rental") {
        tmpTotalSubsaleSales = tmpCombineSale.reduce((total, item) => total + item.totalConvertedRentalPersonalSales, 0);
      } else {  
        tmpTotalSubsaleSales = tmpCombineSale.reduce((total, item) => total + item.totalAllConvertedSubsalesPersonalSales, 0);
      }
      this.setState({
        totalData: {
          ...this.state.totalData,
          totalAgencyComm: numberWithCommas(tmptotalAgencyComm.toFixed(2)),
          totalSubsaleSales: numberWithCommas(tmpTotalSubsaleSales.toFixed(2)),
        },
      });
    }

    safdasdf

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          title={this.state.title}
          projectSales={this.state.projectSales}
          initialProjectSales={this.state.initialProjectSales}
          selectedSales={this.state.selectedSales}
          searchParams={this.state.searchParams}
          showAgentsWithoutSales={this.state.showAgentsWithoutSales}
          showSubmittedUnitOnly={this.state.showSubmittedUnitOnly}
          showAgentSales={this.state.showAgentSales}
          onLoadSales={this.state.loading}
          onLoadBookedUnit={this.state.bookedUnitLoading}
          totalData={this.state.totalData}
          view={this.state.view}
          subsalesType={this.state.subsalesType}
          showSearchTips={this.state.showSearchTips}
          submittedSubsales={this.state.submittedSubsales}
          combinedSubsales={this.state.combinedSubsales}
          initialcombinedSubsales={this.state.initialcombinedSubsales}
          initialSubmittedSubsales={this.state.initialSubmittedSubsales}
          onChangeSalesHOC={this.onChangeSalesHOC}
          getTeamSales={this.getTeamSales}
          onChangeSubsalesType={this.onChangeSubsalesType}
          processSubsalesSubmittedSales={this.processSubsalesSubmittedSales}
          onChangeSubsalesUnit={this.onChangeSubsalesUnit}
          updateFilteredData={this.updateFilteredData}
          hideFunction={this.hideFunction}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
    storeTeamSalesDate,
  })(WithHOC);
};

export default HOC;
