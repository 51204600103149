import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import Moment from "moment";
import { BsInfoCircleFill } from "react-icons/bs";

import CustomTab from "components/Tab";
import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import AtlasIcon from "components/Icon/atlasIcon";
import ModuleHeader from "components/ModuleHeader";
import CustomSearchForm from "components/Form/search";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import ModalDetails from "./Details";
import CustomTable from "./table";

import MySalesHOC from "./actions";
import MySubsalesHOC from "./actions/subsales";
import VersionCheckHOC from "actions/versionCheck";
import TeamViewSummary from "./teamView";

import defaultAvatar from "assets/images/av-male.jpg";
import Lightbox from "react-awesome-lightbox";
import { initGA } from "utils/ga";

import {
  ProjectData,
  SubsalesData,
  submittedUnitData,
  allSubsalesColumn,
} from "./tableConfig";

const subsalesOptions = [
  {
    id: 0,
    label: "All",
    value: "all",
  },
  {
    id: 1,
    label: "Sale",
    value: "Sales",
  },
  {
    id: 2,
    label: "Rental",
    value: "Rentals",
  },
];

const projectStat = [
  {
    label: "Total Estimated Commission",
    value: "totalEstimatedCommission",
    icon: "moneys",
    color: "#3B82F6",
  },
  {
    label: "Total Paid Commission",
    value: "totalPaidCommission",
    icon: "wallet-1",
    color: "#14B8A6",
  },
];

const subsalesStat = [
  {
    label: "Total Agency Commission",
    value: "totalAgencyComm",
    icon: "moneys",
    color: "#9061F9",
  },
  {
    label: "Total Paid Commission",
    value: "totalPaidCommission",
    icon: "wallet-1",
    color: "#14B8A6",
  },
];

class MySales extends Component {
  state = {
    rowData: [],
    projectLayout: [],
    subsalesLayout: [],
    detailType: "",
    subsalesData: [],
    openImage: false,
  };

  componentDidMount = () => {
    initGA("/dashboard/personal/my-sales");
    this.getPersonalSales();
  };

  getPersonalSales = (search) => {
    const url = window.location.href;
    const { startDate, endDate } = this.props;
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    if (url.indexOf("/admin/") !== -1) {
      this.props.onChangeMysalesHOC(true, "adminView");
      this.props.onChangeMysalesHOC(
        `${this.props.display_name}'s Sales`,
        "title",
      );

      this.props.getPersonalSales(this.props.encoded_identity);
      this.props.getPersonalSubsales(
        this.props.encoded_identity,
        startDate,
        endDate,
      );
    } else if (url.indexOf("team") !== -1) {
      initGA("/dashboard/team/personal-sales/");
      const { teamSalesDateReducer: teamDate, salesReducer } = this.props.data;
      const { agentName, encodedMobilePhone, encodedMobileNumber } = salesReducer.selectedAgent;

      this.props.onChangeMysalesHOC(true, "teamView");
      this.props.onChangeMysalesHOC(`${agentName}'s Sales`, "title");
      !search && this.props.onChangeMysalesHOC("", "view");
      this.props.getLeaderBoardData(encodedMobilePhone || encodedMobileNumber);
      this.props.getTeamPersonalSales(
        encodedMobilePhone || encodedMobileNumber,
        teamDate.startDate,
        teamDate.endDate,
      );
      this.props.getTeamPersonalSubsales(
        encodedMobilePhone || encodedMobileNumber,
        teamDate.startDate,
        teamDate.endDate,
      );
    } else {
      this.props.onChangeMysalesHOC("My Sales", "title");

      this.props.getPersonalSales(tmpProfileReducer.encoded_identity);
      this.props.getPersonalSubsales(
        tmpProfileReducer.encoded_identity,
        startDate,
        endDate,
      );
    }
  };

  onAdminClickBackButton = () => {
    if (this.props.adminView) {
      this.props.clickBack();
    }
    if (this.props.teamView) {
      this.props.onClose();
    }
  };

  onSelectStartDate = (val) => {
    val
      ? this.props.onChangeMysalesHOC(
          Moment(val).format("YYYY-MM-DD"),
          "startDate",
        )
      : this.props.onChangeMysalesHOC(
          Moment()
            .year(new Date().getFullYear())
            .month(0)
            .date(1)
            .format("YYYY-MM-DD"),
          "startDate",
        );
  };

  onSelectEndDate = (val) => {
    val
      ? this.props.onChangeMysalesHOC(
          Moment(val).format("YYYY-MM-DD"),
          "endDate",
        )
      : this.props.onChangeMysalesHOC(
          Moment(new Date()).format("YYYY-MM-DD"),
          "endDate",
        );
  };

  filterData = (val, data) => {
    let tempData = [];
    switch (val) {
      case "Rental":
        tempData = _.filter(data, (item) => {
          return item.type === "Rental";
        });
        break;
      case "Sale":
        tempData = _.filter(data, (item) => {
          return item.type === "Sale";
        });
        break;
      default:
        tempData = data;
    }
    return tempData;
  };

  onChangeSubsalesType = (val) => {
    if (this.props.showSubmittedUnitOnly === "1") {
      let tempData = this.filterData(val, this.props.initialSubmittedSubsales);
      this.props.onChangePersonalSubsalesHOC(tempData, "currentData");
    } else if (this.props.showSubmittedUnitOnly === "2") {
      if (val === "All") {
        this.props.onChangePersonalSubsalesHOC(
          this.props.combineSubsales,
          "currentData",
        );
      } else {
        let tempData = this.filterData(val, this.props.initialCombineSubsales);
        this.props.onChangePersonalSubsalesHOC(tempData, "currentData");
      }
    } else if (this.props.showSubmittedUnitOnly === "") {
      if (val === "All") {
        this.props.onChangePersonalSubsalesHOC(
          this.props.personalSubsales,
          "currentData",
        );
      } else {
        let tempData = this.filterData(val, this.props.personalSubsales);
        this.props.onChangePersonalSubsalesHOC(tempData, "currentData");
      }
    }
    this.props.onChangePersonalSubsalesHOC(val, "subsalesType");
  };

  updateTotalSubsaleTable = (paidComm, agnComm) => {
    if (this.props.showSubmittedUnitOnly === "1") {
      this.props.onChangePersonalSubsalesHOC(
        {
          totalAgencyComm: agnComm,
        },
        "submittedSubsalesComm",
      );
    } else if (this.props.showSubmittedUnitOnly === "2") {
      this.props.onChangePersonalSubsalesHOC(
        {
          totalAgencyComm: agnComm,
          totalPaidCommission: paidComm,
        },
        "combineSubsalesComm",
      );
    } else {
      this.props.onChangePersonalSubsalesHOC(
        {
          totalAgencyComm: agnComm,
          totalPaidCommission: paidComm,
        },
        "personalSubsalesComm",
      );
    }
  };

  renderImageDialog = () => {
    let image = this.props.agentSalesSummary.large_avatar_url;
    return (
      <AtlasDialog open={this.state.openImage}>
        <Lightbox
          image={
            image && !_.includes(image, "missing.png") ? image : defaultAvatar
          }
          title={"Agent Avatar"}
          buttonAlign={"center"}
          onClose={() => {
            this.setState({ openImage: false });
          }}
        />
      </AtlasDialog>
    );
  };

  renderAdditionalFilter = () => {
    const { showSubmittedUnitOnly, subsalesType, onChangePersonalSubsalesHOC } =
      this.props;
    const url = window.location.href;

    const onChangeBookedUnit = (val) => {
      if (val) {
        if (val === "1") {
          let tempData = this.filterData(
            this.props.subsalesType,
            this.props.initialSubmittedSubsales,
          );
          this.props.onChangePersonalSubsalesHOC(tempData, "currentData");
        } else if (val === "2") {
          let tempData = this.filterData(
            this.props.subsalesType,
            this.props.initialCombineSubsales,
          );
          this.props.onChangePersonalSubsalesHOC(tempData, "currentData");
        }
      } else {
        let tempData = this.filterData(
          this.props.subsalesType,
          this.props.personalSubsales,
        );
        this.props.onChangePersonalSubsalesHOC(tempData, "currentData");
      }
      onChangePersonalSubsalesHOC(val, "showSubmittedUnitOnly");
    };

    return (
      <div className="d-block w-100">
        <CustomTab
          sections={subsalesOptions}
          containerClass={"at-sales__tabs"}
          selectedSection={subsalesType}
          onSelectSection={(val) => this.onChangeSubsalesType(val)}
        />
        <div className="at-sale__float-button-cont">
          <button
            className={`btn-float ${showSubmittedUnitOnly === "2" ? "btn-float-selected" : ""}`}
            onClick={() => onChangeBookedUnit("2")}
          >
            Show All Unit
          </button>
          <button
            className={`btn-float ${showSubmittedUnitOnly === "" ? "btn-float-selected" : ""}`}
            onClick={() => onChangeBookedUnit("")}
          >
            Only Show Converted Unit
          </button>
          <button
            className={`btn-float ${showSubmittedUnitOnly === "1" ? "btn-float-selected" : ""}`}
            onClick={() => onChangeBookedUnit("1")}
          >
            Only Show Booked Unit
          </button>
        </div>
      </div>
    );
  };

  renderTabContent = () => {
    const {
      personalSales,
      personalSalesComm,
      personalSubsalesComm,
      showSubmittedUnitOnly,
      combineSubsalesComm,
      submittedSubsalesComm,
      getPersonalSalesDetails,
      onChangeMysalesHOC,
      teamView,
      subsalesType,
      currentData,
      view,
    } = this.props;
    switch (view) {
      case "Project":
      case "Project Sales":
        return (
          <>
            <div className="row" style={{ margin: "0px -15px" }}>
              {projectStat.map((item, index) => (
                <div key={index} className="col-md-6 px-1 mb-2">
                  <div
                    style={{ padding: 12 }}
                    className="at-form__content d-flex align-items-center mb-0 h-100 w-100"
                  >
                    <div
                      className="at-card--stat__general-icon-cont"
                      style={{ backgroundColor: item.color }}
                    >
                      <AtlasIcon svgHref={`atlas-${item.icon}`} />
                    </div>
                    <div className="at-card--stat__general-category mb-0">
                      <p>{item.label}</p>
                      <span>{personalSalesComm[item.value]}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="at-card-container mt-2">
              <CustomTable
                className={"mb-20 at-table--personal-sales"}
                columns={ProjectData(teamView)}
                pagination={true}
                actionColumnContent={[
                  {
                    name: "details",
                    onClick: (rowData) =>
                      this.setState({ detailType: "project" }, () => {
                        this.props.getPersonalSalesDetails(
                          rowData.encodedUnitId,
                          rowData.encodedMobilePhone,
                        );
                      }),
                  },
                ]}
                rowData={personalSales || []}
                estimatedCommProps={"estimatedTotalCommission"}
                agencyCommProps={"paidTotalCommission"}
                updateSimpleTable={(estComm, agnComm) => {
                  onChangeMysalesHOC(
                    {
                      totalEstimatedCommission: estComm,
                      totalPaidCommission: agnComm,
                    },
                    "personalSalesComm",
                  );
                }}
              />
            </div>
          </>
        );
      case "Subsales":
      case "Subsales Sales":
        return (
          <>
            <div className="row" style={{ margin: "0px -15px" }}>
              {subsalesStat.map((item, index) => {
                let tempData = showSubmittedUnitOnly
                  ? showSubmittedUnitOnly === "1"
                    ? submittedSubsalesComm
                    : combineSubsalesComm
                  : personalSubsalesComm;
                if (
                  showSubmittedUnitOnly !== "1" ||
                  item.value === "totalAgencyComm"
                ) {
                  return (
                    <div key={index} className="col-md-6 px-1 mb-2">
                      <div
                        style={{ padding: 12 }}
                        className="at-form__content d-flex align-items-center mb-0 h-100 w-100"
                      >
                        <div
                          className="at-card--stat__general-icon-cont"
                          style={{ backgroundColor: item.color }}
                        >
                          <AtlasIcon svgHref={`atlas-${item.icon}`} />
                        </div>
                        <div className="at-card--stat__general-category mb-0">
                          <p>{item.label}</p>
                          <span>{tempData[item.value]}</span>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
            <div className="at-card-container">
              <CustomTable
                className={"mb-3 at-table--personal-sales"}
                columns={
                  showSubmittedUnitOnly === "1"
                    ? submittedUnitData
                    : showSubmittedUnitOnly === "2" ||
                        showSubmittedUnitOnly === ""
                      ? allSubsalesColumn
                      : SubsalesData(teamView)
                }
                pagination={true}
                actionColumnContent={[
                  {
                    name: "details",
                    disabled: (rowData) => rowData.booked_unit,
                    onClick: (rowData) =>
                      this.setState({ detailType: "subsales" }, () => {
                        getPersonalSalesDetails(
                          rowData.encodedUnitId,
                          rowData.encodedMobilePhone,
                        );
                      }),
                  },
                ]}
                rowData={currentData}
                renderAdditionalContent={this.renderAdditionalFilter()}
                estimatedCommProps={
                  showSubmittedUnitOnly === "1"
                    ? "estimated_comm"
                    : "paidTotalCommission"
                }
                agencyCommProps={
                  showSubmittedUnitOnly === "1" || showSubmittedUnitOnly === "2"
                    ? "agency_comm"
                    : "grossCommission"
                }
                updateSimpleTable={this.updateTotalSubsaleTable}
              />
            </div>
          </>
        );
      default:
        break;
    }
  };

  renderCardContent = () => {
    const { data, startDate, endDate, onChangeMysalesHOC } = this.props;

    return (
      <>
        {this.props.teamView && (
          <TeamViewSummary
            data={data}
            openImage={() => this.setState({ openImage: true })}
            onChangeMysalesHOC={this.props.onChangeMysalesHOC}
            agentSalesSummary={this.props.agentSalesSummary}
            teamViewSummary={this.props.teamViewSummary}
            leaderBoardData={this.props.leaderBoardData}
          />
        )}
        {!this.props.teamView && (
          <CustomTab
            sections={[
              { label: "Project", icon: "atlas-building-4" },
              { label: "Subsales", icon: "atlas-building-3" },
            ]}
            containerStyle={{ marginBottom: 16 }}
            selectedSection={this.props.view}
            onSelectSection={(val) => onChangeMysalesHOC(val, "view")}
          />
        )}
        {this.props.view && (
          <>
            {!this.props.teamView && (
              <div className="at-form__content mb-3" style={{ padding: 15 }}>
                <CustomSearchForm
                  showHideAgentsCheckbox={false}
                  onClickSubmit={() => this.getPersonalSales(true)}
                  onSelectStartDate={(val) => this.onSelectStartDate(val)}
                  onSelectEndDate={(val) => this.onSelectEndDate(val)}
                  selectedStartDate={startDate}
                  selectedEndDate={endDate}
                />
              </div>
            )}
            {this.renderTabContent()}
          </>
        )}
      </>
    );
  };

  render = () => {
    const {
      title,
      teamView,
      adminView,
      onLoadMySales,
      onLoadMySubsales,
      personalSalesDetails,
    } = this.props;

    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        {teamView && (
          <ModalDialog
            title={title}
            fullWidth={true}
            fullHeight={true}
            onLoad={onLoadMySales || onLoadMySubsales}
            children={this.renderCardContent()}
            onClose={() => this.onAdminClickBackButton()}
          />
        )}
        {!teamView && (
          <>
            <ModuleHeader
              title={title}
              atlasIcon={"atlas-my-sales"}
              description={
                !adminView &&
                !teamView && (
                  <>
                    Review your personal sales records. Click on 'Project' or
                    'Subsales' to explore your performance in different
                    segments. Use the date range search and filter options for
                    specific searches. In 'Subsales,' discover your Total Agency
                    and Paid Commission, automatically calculated based on your
                    sales type and status selections. For a detailed sale
                    record, click the
                    <BsInfoCircleFill
                      style={{
                        width: 18,
                        height: 18,
                        color: "#007bff",
                        margin: "0px 4px",
                      }}
                    />
                    icon.
                  </>
                )
              }
              showPABadge={role === "Personal Assistant"}
              backButton={{
                onShow: adminView,
                onClick: () => this.onAdminClickBackButton(),
              }}
            />
            <CustomCard cardContent={this.renderCardContent()} />
          </>
        )}
        {this.props.detailsModalOpen && (
          <ModalDialog
            title={"Sales Details"}
            fullWidth={true}
            fullHeight={true}
            onClose={() =>
              this.props.onChangeMysalesHOC(false, "detailsModalOpen")
            }
            children={
              <ModalDetails
                personalSalesDetails={personalSalesDetails}
                onLoadMySales={onLoadMySales}
                detailType={this.state.detailType}
                getPersonalSalesDetails={this.props.getPersonalSalesDetails}
                onClose={() =>
                  this.props.onChangeMysalesHOC(false, "detailsModalOpen")
                }
              />
            }
          />
        )}
        {(onLoadMySales || onLoadMySubsales) && <LoadingModal />}
        {this.renderImageDialog()}
      </>
    );
  };
}

export default compose(MySalesHOC, MySubsalesHOC, VersionCheckHOC)(MySales);
