import { getItem } from 'utils/tokenStore';

export const SuperAdminRoutingData = [
  {
    name: 'Dashboard',
    icon: 'atlas-grid-5',
    navigateTo: '/dashboard/statistic',
    type: 'menu'
  },
  {
    name: 'User Management',
    icon: 'atlas-user-management',
    children: [
      {
        name: 'Users',
        icon: 'atlas-profile-circle',
        navigateTo: '/dashboard/admin/user-management'
      },
      {
        name: 'Teams',
        icon: 'atlas-teams',
        navigateTo: '/dashboard/admin/team-management'
      },
      {
        name: 'Permission Groups',
        icon: 'atlas-key',
        navigateTo: '/dashboard/superadmin/permission-group-user'
      },
      {
        name: 'New Agent Approvals',
        icon: 'atlas-profile-tick',
        navigateTo: '/dashboard/admin/agent-approval'
      },
      {
        name: 'Admin Roles',
        icon: 'atlas-admin-roles',
        navigateTo: '/dashboard/admin/forms/admin-roles-management'
      },
      {
        name: 'Sessions',
        icon: 'atlas-sessions',
        navigateTo: '/dashboard/admin/sessions'
      },
      {
        name: 'OTP Verification',
        icon: 'atlas-shield-tick',
        navigateTo: '/dashboard/admin/verification'
      },
      {
        name: 'License Renewal',
        icon: 'atlas-personalcard',
        navigateTo: '/dashboard/admin/rea-renewal'
      },
      {
        name: 'Agreements',
        icon: 'atlas-cobroke-centre',
        navigateTo: '/dashboard/admin/agreement-management'
      }
    ]
  },
  {
    name: 'Branch Management',
    icon: 'atlas-branch-management',
    children: [
      {
        name: 'Branches',
        icon: 'atlas-buildings-2',
        navigateTo: '/dashboard/admin/branch-management'
      },
      {
        name: 'QR Based Door Entry',
        icon: 'atlas-qr-door',
        navigateTo: '/dashboard/admin/qr-door-entry'
      },
      {
        name: 'Meeting Rooms',
        icon: 'atlas-meeting-rooms',
        navigateTo: '/dashboard/admin/meeting-room'
      },
      {
        name: 'Meeting Hub',
        icon: 'atlas-people-linear',
        navigateTo: '/dashboard/admin/meeting-hub'
      }
    ]
  },
  {
    name: 'Event Management',
    icon: 'atlas-event-management',
    children: [
      {
        name: 'Events',
        icon: 'atlas-calendar-circle',
        navigateTo: '/dashboard/admin/event-training'
      },
      {
        name: 'IQI Calendar',
        icon: 'atlas-calendar',
        navigateTo: '/dashboard/iqi-calendar'
      }
    ]
  },
  {
    name: 'Subsales Management',
    icon: 'atlas-subsales-managements',
    children: [
      {
        name: 'Listing Centre',
        icon: 'atlas-building-3',
        navigateTo: '/dashboard/superadmin/subsales'
      },
      {
        name: 'Confirmation Forms Requests',
        icon: 'atlas-note-add',
        navigateTo: '/dashboard/admin/forms/confirmation-forms-requests'
      },
      {
        name: "Agent's Subsales Accesibility",
        icon: 'atlas-lock-circle',
        navigateTo: '/dashboard/superadmin/subsales-accessibility'
      },
      {
        name: 'Co-Agencies',
        icon: 'atlas-building',
        navigateTo: '/dashboard/admin/co-agency',
        type: 'menu'
      },
      {
        name: "Tenancy Agreements",
        icon: "svg-react-clipboard",
        navigateTo: "/dashboard/admin/tenancy-agreements",
      },
      {
        name: "Inventory Checklist",
        icon: "atlas-lamp",
        navigateTo: "/dashboard/admin/inventory-checklist",
      },
      { label: "SUBSALES" },
      {
        name: "Subsales Claims",
        icon: "atlas-subsales-claims",
        navigateTo: "/dashboard/admin/subsales-claims",
      },
      {
        name: 'Appointment Letters',
        icon: 'atlas-appointment-letter',
        navigateTo: '/dashboard/admin/forms/appointment-letter-management'
      },
      {
        name: 'Confirmation Forms',
        icon: 'atlas-confirmation-form',
        navigateTo: '/dashboard/admin/forms/confirmation-forms-management'
      },
      {
        name: 'Co-broke Letters',
        icon: 'atlas-cobroke-letter',
        navigateTo: '/dashboard/admin/forms/cobroke-letter-management'
      },
      {
        name: 'Authorisation Forms to Secure Property',
        icon: 'atlas-authorisation-form',
        navigateTo: '/dashboard/admin/forms/authorisation-form-management'
      },
      {
        name: "Physical Forms",
        icon: "atlas-document-sketch",
        navigateTo: "/dashboard/admin/forms/paper-forms-management",
      },
    ],
  },
  {
    name: 'Global Network Management',
    icon: 'atlas-global-network',
    children: [
      {
        name: 'Global Network',
        icon: 'atlas-global',
        navigateTo: '/dashboard/global-network'
      },
      {
        name: 'Landmark Management',
        icon: 'atlas-landmark-management',
        navigateTo: '/dashboard/admin/country-landmark-management'
      }
    ]
  },
  {
    name: 'Marketing Centre',
    icon: 'atlas-marketing-centre',
    children: [
      {
        name: 'Leads',
        icon: 'atlas-leads',
        navigateTo: '/dashboard/admin/lead-management'
      },
      {
        name: 'Presentation Centre',
        icon: 'atlas-presentation-chart',
        navigateTo: '/dashboard/admin/presentation-centre'
      },
      {
        name: 'Video Templates',
        icon: 'atlas-video-vertical',
        navigateTo: '/dashboard/admin/video-template-management'
      },
      {
        name: 'Personalised Videos',
        icon: 'atlas-video-add',
        navigateTo: '/dashboard/admin/personalised-video'
      },
      {
        name: 'Graphic Templates',
        icon: 'atlas-gallery-add',
        navigateTo: '/dashboard/admin/graphic-studio-template'
      },
      {
        name: 'Graphic Studio',
        icon: 'atlas-gallery-edit',
        navigateTo: '/dashboard/admin/graphic-studio'
      },
      {
        name: 'Email Templates',
        icon: 'atlas-sms-tracking',
        navigateTo: '/dashboard/admin/email-templates'
      },
      {
        name: 'Email Campaigns',
        icon: 'atlas-sms-edit',
        navigateTo: '/dashboard/admin/email-campaigns'
      }
    ]
  },
  {
    name: 'Atlas Store',
    icon: 'atlas-bag-happy',
    children: [
      {
        name: 'Products',
        icon: 'atlas-element-equal',
        navigateTo: '/dashboard/admin/product-management'
      },
      {
        name: 'Orders',
        icon: 'atlas-bag-tick-2',
        navigateTo: '/dashboard/admin/order-management'
      },
      {
        name: 'Coupons',
        icon: 'atlas-ticket-discount',
        navigateTo: '/dashboard/admin/coupon-management'
      },
      {
        name: 'Name Card Batches',
        icon: 'atlas-personalcard',
        navigateTo: '/dashboard/admin/namecard-management'
      }
    ]
  },
  {
    name: 'Project Management',
    icon: 'atlas-project-management',
    children: [
      {
        name: 'Project Centre',
        icon: 'atlas-building-4',
        navigateTo: '/dashboard/project-listing'
      },
      {
        name: 'Projects',
        icon: 'atlas-projects',
        navigateTo: '/dashboard/admin/project-management'
      },
      {
        name: 'Project Sales',
        icon: 'atlas-project-sales',
        navigateTo: '/dashboard/admin/sale-management'
      }
    ]
  },
  {
    name: 'Training Management',
    icon: 'atlas-training-management',
    children: [
      {
        name: 'Training Attendances',
        icon: 'atlas-training-attendance',
        navigateTo: '/dashboard/admin/training-centre'
      },
      {
        name: 'Current Exam',
        icon: 'atlas-current-exam',
        navigateTo: '/dashboard/admin/exam-integration'
      },
      {
        name: 'Exam Results',
        icon: 'atlas-exam-results',
        navigateTo: '/dashboard/admin/exam-management'
      },
      {
        name: 'Tutorials',
        icon: 'atlas-bookmark-2',
        navigateTo: '/dashboard/admin/tutorials'
      }
    ]
  },
  {
    name: 'Analytics Centre',
    icon: 'atlas-chart-3',
    children: [
      {
        name: 'User Activities',
        icon: 'atlas-chart-2',
        navigateTo: '/dashboard/admin/analytic-centre'
      },
      {
        name: "Admin Activities",
        icon: "atlas-chart-4",
        navigateTo: "/dashboard/admin/admin-activities",
      },
      {
        name: "Secondary Market",
        icon: "atlas-diagram",
        navigateTo: "/dashboard/analytic-centre",
      },
    ],
  },
  {
    name: "Due Diligence Hub",
    icon: "atlas-amla-report",
    children: [
      {
        name: "Customer Due Diligence",
        icon: "atlas-user-search",
        navigateTo: "/dashboard/admin/cdd",
      },
      {
        name: "AMLA Exceptions",
        icon: "atlas-clipboard-text",
        navigateTo: "/dashboard/admin/amla-report",
      },
    ],
  },
  {
    name: 'Valuations',
    icon: 'atlas-valuations',
    children: [
      {
        name: 'Real Estate/Corporate',
        icon: 'atlas-valuations',
        navigateTo: '/dashboard/valuations/corporate'
      }
    ]
  },
  {
    name: 'Dashboard Management',
    icon: 'atlas-grid-edit',
    children: [
      {
        name: 'Newsfeeds',
        icon: 'atlas-document-text',
        navigateTo: '/dashboard/admin/newsfeed-management'
      },
      {
        name: 'MOTD',
        icon: 'atlas-messages-1',
        navigateTo: '/dashboard/admin/motd-management'
      }
    ]
  },
  {
    name: 'App Management',
    icon: 'atlas-mobile',
    children: [
      {
        name: 'App Themes',
        icon: 'atlas-brush-4',
        navigateTo: '/dashboard/admin/app-theme-management'
      },
      {
        name: 'App Version',
        icon: 'atlas-speedometer',
        navigateTo: '/dashboard/admin/app-version'
      }
    ]
  },
  {
    name: 'API Keys',
    icon: 'atlas-driver-2',
    navigateTo: '/dashboard/admin/api-key-management'
  },
  {
    name: 'IQI Platforms',
    icon: 'atlas-iqi-platforms',
    children: [
      {
        name: 'IQI Drive',
        icon: 'atlas-folder',
        navigateTo: '/dashboard/iqi-drive/list'
      },
      {
        name: 'IQI Store',
        icon: 'atlas-shopping-card',
        navigateTo: '',
        href: 'https://store.iqiglobal.com/'
      },
      {
        name: 'IQI Academy',
        icon: 'atlas-teacher',
        navigateTo: '',
        href: `https://academy.iqiglobal.com/atlas-academy-login?token=${getItem('IQI_ATLAS_JWT_TOKEN')}`
      },
      {
        name: `JIQI's Cafe`,
        icon: 'atlas-jiqi-cafe',
        navigateTo: '/dashboard/jiqi-cafe'
      }
    ]
  }
];
