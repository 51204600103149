export const types = [
  {
    id: 1,
    name: "Sub-Sale",
    value: 1,
    label: "Sub-Sale",
  },
  {
    id: 2,
    name: "Sub-Rent",
    value: 2,
    label: "Sub-Rent",
  },
];

export const statuses = [
  {
    id: 0,
    name: "Draft",
    value: 0,
    label: "Draft",
  },
  {
    id: 1,
    name: "Active",
    value: 1,
    label: "Active",
  },
  {
    id: 2,
    name: "Inactive/Expired",
    value: 2,
    label: "Inactive/Expired",
  },
  {
    id: 4,
    name: "Sold/Rent Out",
    value: 4,
    label: "Sold/Rent Out",
  },
  {
    id: 5,
    name: "Deleted",
    value: 5,
    label: "Deleted",
  },
];

export const measurements = [
  {
    id: 0,
    name: "",
    value: 0,
    label: "",
  },
  {
    id: 1,
    name: "Square Feet",
    value: 1,
    label: "Square Feet",
  },
  {
    id: 2,
    name: "Square Meter",
    value: 2,
    label: "Square Meter",
  },
  {
    id: 3,
    name: "Acres",
    value: 3,
    label: "Acres",
  },
  {
    id: 4,
    name: "Hectare",
    value: 4,
    label: "Hectare",
  },
];

export const tenure_types = [
  {
    id: 1,
    name: "Freehold",
    value: 1,
    label: "Freehold",
  },
  {
    id: 2,
    name: "Lease Tenancy",
    value: 2,
    label: "Lease Tenancy",
  },
  {
    id: 3,
    name: "Leasehold",
    value: 3,
    label: "Leasehold",
  },
  {
    id: 4,
    name: "Malay Reserved Land",
    value: 4,
    label: "Malay Reserved Land",
  },
  {
    id: 5,
    name: "Unknown",
    value: 5,
    label: "Unknown",
  },
];

export const title_types = [
  {
    id: 1,
    name: "Individual",
    value: 1,
    label: "Individual",
  },
  {
    id: 2,
    name: "Strata",
    value: 2,
    label: "Strata",
  },
  {
    id: 3,
    name: "Unknown",
    value: 3,
    label: "Unknown",
  },
];

export const furnishing_statuses = [
  {
    id: 1,
    name: "Fully Furnished",
    value: 1,
    label: "Fully Furnished",
  },
  {
    id: 5,
    name: "Fully Fitted",
    value: 5,
    label: "Fully Fitted",
  },
  {
    id: 2,
    name: "Partially Furnished",
    value: 2,
    label: "Partially Furnished",
  },
  {
    id: 3,
    name: "Unfurnished",
    value: 3,
    label: "Unfurnished",
  },
  {
    id: 4,
    name: "Unknown",
    value: 4,
    label: "Unknown",
  },
];

export const directions = [
  {
    id: 1,
    name: "North",
    value: 1,
    label: "North",
  },
  {
    id: 2,
    name: "NorthEast",
    value: 2,
    label: "NorthEast",
  },
  {
    id: 3,
    name: "East",
    value: 3,
    label: "East",
  },
  {
    id: 4,
    name: "SouthEast",
    value: 4,
    label: "SouthEast",
  },
  {
    id: 5,
    name: "South",
    value: 5,
    label: "South",
  },
  {
    id: 6,
    name: "SouthWest",
    value: 6,
    label: "SouthWest",
  },
  {
    id: 7,
    name: "West",
    value: 7,
    label: "West",
  },
  {
    id: 8,
    name: "NorthWest",
    value: 8,
    label: "NorthWest",
  },
  {
    id: 9,
    name: "Unknown",
    value: 9,
    label: "Unknown",
  },
];

export const occupancies = [
  {
    id: 1,
    name: "Owner Occupied",
    value: 1,
    label: "Owner Occupied",
  },
  {
    id: 2,
    name: "Tenanted",
    value: 2,
    label: "Tenanted",
  },
  {
    id: 4,
    name: "Vacant",
    value: 4,
    label: "Vacant",
  },
  {
    id: 3,
    name: "Unknown",
    value: 3,
    label: "Unknown",
  },
];

export const unit_types = [
  {
    id: 0,
    name: "",
    value: 0,
    label: "",
  },
  {
    id: 1,
    name: "Corner",
    value: 1,
    label: "Corner",
  },
  {
    id: 2,
    name: "Duplex",
    value: 2,
    label: "Duplex",
  },
  {
    id: 3,
    name: "Endlot",
    value: 3,
    label: "Endlot",
  },
  {
    id: 4,
    name: "Intermediate",
    value: 4,
    label: "Intermediate",
  },
  {
    id: 5,
    name: "Penthouse",
    value: 5,
    label: "Penthouse",
  },
  {
    id: 6,
    name: "Studio",
    value: 6,
    label: "Studio",
  },
  {
    id: 7,
    name: "Triplex",
    value: 7,
    label: "Triplex",
  },
  {
    id: 8,
    name: "Unknown",
    value: 8,
    label: "Unknown",
  },
  {
    id: 9,
    name: "SOHO",
    value: 9,
    label: "SOHO",
  },
];

export const bedroom_options = [
  {
    id: 54,
    name: "N/A",
    value: 54,
    label: "N/A",
    quantity: 0,
  },
  {
    id: 1,
    name: "1",
    value: 1,
    label: "1",
    quantity: 1,
  },
  {
    id: 2,
    name: "1+1",
    value: 2,
    label: "1+1",
    quantity: 2,
  },
  {
    id: 3,
    name: "1+2",
    value: 3,
    label: "1+2",
    quantity: 3,
  },
  {
    id: 4,
    name: "2",
    value: 4,
    label: "2",
    quantity: 2,
  },
  {
    id: 5,
    name: "2+1",
    value: 5,
    label: "2+1",
    quantity: 3,
  },
  {
    id: 6,
    name: "2+2",
    value: 6,
    label: "2+2",
    quantity: 4,
  },
  {
    id: 7,
    name: "3",
    value: 7,
    label: "3",
    quantity: 3,
  },
  {
    id: 8,
    name: "3+1",
    value: 8,
    label: "3+1",
    quantity: 4,
  },
  {
    id: 9,
    name: "3+2",
    value: 9,
    label: "3+2",
    quantity: 5,
  },
  {
    id: 10,
    name: "4",
    value: 10,
    label: "4",
    quantity: 4,
  },
  {
    id: 11,
    name: "4+1",
    value: 11,
    label: "4+1",
    quantity: 5,
  },
  {
    id: 12,
    name: "4+2",
    value: 12,
    label: "4+2",
    quantity: 6,
  },
  {
    id: 13,
    name: "5",
    value: 13,
    label: "5",
    quantity: 5,
  },
  {
    id: 14,
    name: "5+1",
    value: 14,
    label: "5+1",
    quantity: 6,
  },
  {
    id: 15,
    name: "5+2",
    value: 15,
    label: "5+2",
    quantity: 7,
  },
  {
    id: 16,
    name: "6",
    value: 16,
    label: "6",
    quantity: 6,
  },
  {
    id: 17,
    name: "6+1",
    value: 17,
    label: "6+1",
    quantity: 7,
  },
  {
    id: 18,
    name: "6+",
    value: 18,
    label: "6+",
    quantity: 6,
  },
  {
    id: 19,
    name: "7",
    value: 19,
    label: "7",
    quantity: 7,
  },
  {
    id: 20,
    name: "7+1",
    value: 20,
    label: "7+1",
    quantity: 8,
  },
  {
    id: 21,
    name: "7+",
    value: 21,
    label: "7+",
    quantity: 7,
  },
  {
    id: 22,
    name: "8",
    value: 22,
    label: "8",
    quantity: 8,
  },
  {
    id: 23,
    name: "8+1",
    value: 23,
    label: "8+1",
    quantity: 9,
  },
  {
    id: 24,
    name: "8+",
    value: 24,
    label: "8+",
    quantity: 8,
  },
  {
    id: 25,
    name: "9",
    value: 25,
    label: "9",
    quantity: 9,
  },
  {
    id: 26,
    name: "9+1",
    value: 26,
    label: "9+1",
    quantity: 10,
  },
  {
    id: 27,
    name: "9+",
    value: 27,
    label: "9+",
    quantity: 9,
  },
  {
    id: 28,
    name: "10",
    value: 28,
    label: "10",
    quantity: 10,
  },
  {
    id: 29,
    name: "10+1",
    value: 29,
    label: "10+1",
    quantity: 11,
  },
  {
    id: 30,
    name: "10+",
    value: 30,
    label: "10+",
    quantity: 10,
  },
  {
    id: 31,
    name: "11",
    value: 31,
    label: "11",
    quantity: 11,
  },
  {
    id: 32,
    name: "11+1",
    value: 32,
    label: "11+1",
    quantity: 12,
  },
  {
    id: 33,
    name: "11+",
    value: 33,
    label: "11+",
    quantity: 11,
  },
  {
    id: 34,
    name: "12",
    value: 34,
    label: "12",
    quantity: 12,
  },
  {
    id: 35,
    name: "12+1",
    value: 35,
    label: "12+1",
    quantity: 13,
  },
  {
    id: 36,
    name: "12+",
    value: 36,
    label: "12+",
    quantity: 12,
  },
  {
    id: 37,
    name: "13",
    value: 37,
    label: "13",
    quantity: 13,
  },
  {
    id: 38,
    name: "13+1",
    value: 38,
    label: "13+1",
    quantity: 14,
  },
  {
    id: 39,
    name: "13+",
    value: 39,
    label: "13+",
    quantity: 13,
  },
  {
    id: 40,
    name: "14",
    value: 40,
    label: "14",
    quantity: 14,
  },
  {
    id: 41,
    name: "14+1",
    value: 41,
    label: "14+1",
    quantity: 15,
  },
  {
    id: 42,
    name: "14+",
    value: 42,
    label: "14+",
    quantity: 14,
  },
  {
    id: 43,
    name: "15",
    value: 43,
    label: "15",
    quantity: 15,
  },
  {
    id: 44,
    name: "15+",
    value: 44,
    label: "15+",
    quantity: 15,
  },
  {
    id: 45,
    name: "16",
    value: 45,
    label: "16",
    quantity: 16,
  },
  {
    id: 46,
    name: "16+",
    value: 46,
    label: "16+",
    quantity: 16,
  },
  {
    id: 47,
    name: "17",
    value: 47,
    label: "17",
    quantity: 17,
  },
  {
    id: 48,
    name: "17+",
    value: 48,
    label: "17+",
    quantity: 17,
  },
  {
    id: 49,
    name: "18",
    value: 49,
    label: "18",
    quantity: 18,
  },
  {
    id: 50,
    name: "18+",
    value: 50,
    label: "18+",
    quantity: 18,
  },
  {
    id: 51,
    name: "19",
    value: 51,
    label: "19",
    quantity: 19,
  },
  {
    id: 52,
    name: "19+",
    value: 52,
    label: "19+",
    quantity: 19,
  },
  {
    id: 53,
    name: "20 Above",
    value: 53,
    label: "20 Above",
    quantity: 20,
  },
];

export const car_park_options = [
  {
    id: 11,
    name: "N/A",
    value: 11,
    label: "N/A",
    quantity: 0,
  },
  {
    id: 0,
    name: "0",
    value: 0,
    label: "0",
    quantity: 0,
  },
  {
    id: 1,
    name: "1",
    value: 1,
    label: "1",
    quantity: 1,
  },
  {
    id: 2,
    name: "2",
    value: 2,
    label: "2",
    quantity: 2,
  },
  {
    id: 3,
    name: "3",
    value: 3,
    label: "3",
    quantity: 3,
  },
  {
    id: 4,
    name: "4",
    value: 4,
    label: "4",
    quantity: 4,
  },
  {
    id: 5,
    name: "5",
    value: 5,
    label: "5",
    quantity: 5,
  },
  {
    id: 6,
    name: "6",
    value: 6,
    label: "6",
    quantity: 6,
  },
  {
    id: 7,
    name: "7",
    value: 7,
    label: "7",
    quantity: 7,
  },
  {
    id: 8,
    name: "8",
    value: 8,
    label: "8",
    quantity: 8,
  },
  {
    id: 9,
    name: "9",
    value: 9,
    label: "9",
    quantity: 9,
  },
  {
    id: 10,
    name: "10",
    value: 10,
    label: "10",
    quantity: 10,
  },
];

export const bathroom_options = [
  {
    id: 11,
    name: "N/A",
    value: 11,
    label: "N/A",
    quantity: 0,
  },
  {
    id: 0,
    name: "0",
    value: 0,
    label: "0",
    quantity: 0,
  },
  {
    id: 1,
    name: "1",
    value: 1,
    label: "1",
    quantity: 1,
  },
  {
    id: 2,
    name: "2",
    value: 2,
    label: "2",
    quantity: 2,
  },
  {
    id: 3,
    name: "3",
    value: 3,
    label: "3",
    quantity: 3,
  },
  {
    id: 4,
    name: "4",
    value: 4,
    label: "4",
    quantity: 4,
  },
  {
    id: 5,
    name: "5",
    value: 5,
    label: "5",
    quantity: 5,
  },
  {
    id: 6,
    name: "6",
    value: 6,
    label: "6",
    quantity: 6,
  },
  {
    id: 7,
    name: "7",
    value: 7,
    label: "7",
    quantity: 7,
  },
  {
    id: 8,
    name: "8",
    value: 8,
    label: "8",
    quantity: 8,
  },
  {
    id: 9,
    name: "9",
    value: 9,
    label: "9",
    quantity: 9,
  },
  {
    id: 10,
    name: "10",
    value: 10,
    label: "10",
    quantity: 10,
  },
];

export const document_types = [
  { id: 1, name: "Appointment Letter" },
  {
    id: 2,
    name: "Confirmation Letter / Co-Agent Booking Form / Physical Form / Renewal Form",
  },
  { id: 3, name: "Official Receipt" },
  { id: 4, name: "Bank In Slip / Cheque copy / Online transfer slip" },
  {
    id: 5,
    name: "Sales & Purchase Agreement (full signed set)",
  },
  {
    id: 6,
    name: "Tenancy Agreement/Renewal Letter (full signed set)",
  },
  { id: 7, name: "Authorization Letter / Power Attorney Letter" },
  { id: 8, name: "Undertaking Letter" },
  { id: 11, name: "Co Broke Letter" },
  { id: 12, name: "Sijil Setem" },
  { id: 18, name: "Non Stamping / Stamping Exemption Letter" },
  { id: 14, name: "Tax Invoice" },
  { id: 15, name: "Referral NRIC" },
  { id: 9, name: "Authorization Letter for Release/Forfeit" },
  { id: 16, name: "Miscellaneous / Others" },
];

export const claim_statuses = [
  { id: 1, name: "Submitted" },
  { id: 2, name: "Processing" },
  { id: 3, name: "Cancelled" },
  { id: 4, name: "Paid" },
  { id: 5, name: "Completed" },
  { id: 6, name: "Rejected" },
  { id: 7, name: "Reviewing" },
  { id: 8, name: "Bank In Processing" },
];

export const payment_types = [
  { id: 1, name: "Cash" },
  { id: 2, name: "Cheque" },
  { id: 6, name: "Bank Transfer" },
];

export const co_broker_representative_types = (sub_sale_type_id) => [
  {
    id: 1,
    name: sub_sale_type_id === 2 ? "Represent Landlord" : "Represent Vendor",
  },
  {
    id: 2,
    name: sub_sale_type_id === 2 ? "Represent Tenant" : "Represent Purchaser",
  },
];

export const listing_contact_types = [
  { id: 1, name: "Owner" },
  { id: 2, name: "Purchaser/Tenant" },
  { id: 3, name: "Owner's Lawyer" },
  { id: 4, name: "Purchaser/Tenant's Lawyer" },
];

export const claim_types = [
  { id: 1, name: "Claim Commission" },
  { id: 2, name: "Refund" },
  { id: 3, name: "Forfeit" },
  { id: 4, name: "Renewal" },
  { id: 5, name: "Release" },
  { id: 6, name: "Cancel Form" },
  { id: 7, name: "Request Invoice" },
  { id: 8, name: "Request Official Receipt" },
  { id: 9, name: "Request TA Stamping" },
];

export const categories = [
  {
    id: 1,
    name: "Residential",
    value: 1,
    label: "Residential",
    group_types: [
      {
        name: "Apartment/Flat",
        children: [
          {
            id: 2,
            name: "Apartment",
          },
          {
            id: 3,
            name: "Apartment Duplex",
          },
          {
            id: 1,
            name: "Flat",
          },
        ],
      },
      {
        name: "Condo/Serviced Residence",
        children: [
          {
            id: 4,
            name: "Condominium",
          },
          {
            id: 7,
            name: "Duplex",
          },
          {
            id: 10,
            name: "Penthouse",
          },
          {
            id: 5,
            name: "Serviced Apartment",
          },
          {
            id: 6,
            name: "Serviced Residence",
          },
          {
            id: 9,
            name: "Soho",
          },
          {
            id: 11,
            name: "Townhouse Condo",
          },
          {
            id: 8,
            name: "Triplex",
          },
        ],
      },
      {
        name: "Residential Land",
        children: [
          {
            id: 29,
            name: "Residential Land",
          },
        ],
      },
      {
        name: "Semi-D/Bungalow/Villas",
        children: [
          {
            id: 22,
            name: "Bungalow",
          },
          {
            id: 23,
            name: "Bungalow Land",
          },
          {
            id: 28,
            name: "Cluster House",
          },
          {
            id: 88,
            name: "Detached House",
          },
          {
            id: 89,
            name: "Estate House",
          },
          {
            id: 24,
            name: "Link Bungalow",
          },
          {
            id: 21,
            name: "Semi-detached House",
          },
          {
            id: 26,
            name: "Twin courtyard villa",
          },
          {
            id: 25,
            name: "Twin villas",
          },
          {
            id: 90,
            name: "Villas",
          },
          {
            id: 27,
            name: "Zero-lot Bungalow",
          },
        ],
      },
      {
        name: "Terrace/Link/Townhouse",
        children: [
          {
            id: 13,
            name: "1.5-sty Terrace/Link House",
          },
          {
            id: 12,
            name: "1-sty Terrace/Link House",
          },
          {
            id: 15,
            name: "2.5-sty Terrace/Link House",
          },
          {
            id: 14,
            name: "2-sty Terrace/Link House",
          },
          {
            id: 18,
            name: "3.5-sty Terrace/Link House",
          },
          {
            id: 74,
            name: "3 Storey Superlink Homes",
          },
          {
            id: 16,
            name: "3-sty Terrace/Link House",
          },
          {
            id: 20,
            name: "4.5-sty Terrace/Link House",
          },
          {
            id: 19,
            name: "4-sty Terrace/Link House",
          },
          {
            id: 73,
            name: "Double Storey Superlink Homes",
          },
          {
            id: 17,
            name: "Townhouse",
          },
        ],
      },
      {
        name: "HDB",
        children: [
          {
            id: 75,
            name: "HDB 1 RM",
          },
          {
            id: 76,
            name: "HDB 2 RM",
          },
          {
            id: 77,
            name: "HDB 3 RM",
          },
          {
            id: 78,
            name: "HDB 4 RM",
          },
          {
            id: 79,
            name: "HDB 5 RM",
          },
          {
            id: 80,
            name: "HDB Executive Apartment",
          },
          {
            id: 81,
            name: "HDB Executive Maisonette",
          },
          {
            id: 82,
            name: "HDB Jumbo",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Commercial",
    value: 2,
    label: "Commercial",
    group_types: [
      {
        name: "Commercial Land",
        children: [
          {
            id: 43,
            name: "Commercial Land",
          },
        ],
      },
      {
        name: "Hotel/Resort",
        children: [
          {
            id: 42,
            name: "Hotel/Resort",
          },
        ],
      },
      {
        name: "Shop/Office/Retail Space",
        children: [
          {
            id: 38,
            name: "Bussiness Centre",
          },
          {
            id: 39,
            name: "Commercial Bungalow",
          },
          {
            id: 41,
            name: "Commercial Semi-D",
          },
          {
            id: 40,
            name: "Design Suites",
          },
          {
            id: 83,
            name: "Dormitory",
          },
          {
            id: 84,
            name: "F & B Space",
          },
          {
            id: 85,
            name: "HDB Hawker Stall",
          },
          {
            id: 30,
            name: "Office",
          },
          {
            id: 37,
            name: "Retail-Office",
          },
          {
            id: 35,
            name: "Retail Space",
          },
          {
            id: 31,
            name: "Shop House",
          },
          {
            id: 87,
            name: "Shop Office",
          },
          {
            id: 86,
            name: "Shop Space (Mall)",
          },
          {
            id: 34,
            name: "Sofo",
          },
          {
            id: 33,
            name: "Soho",
          },
          {
            id: 36,
            name: "Sovo",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "Industrial",
    value: 3,
    label: "Industrial",
    group_types: [
      {
        name: "Factory/Warehouse",
        children: [
          {
            id: 47,
            name: "Detached factory",
          },
          {
            id: 44,
            name: "Factory",
          },
          {
            id: 49,
            name: "Light Industrial",
          },
          {
            id: 48,
            name: "Link Factory",
          },
          {
            id: 45,
            name: "Semi-D Factory",
          },
          {
            id: 46,
            name: "Warehouse",
          },
        ],
      },
      {
        name: "Industrial Land",
        children: [
          {
            id: 50,
            name: "Industrial Land",
          },
        ],
      },
    ],
  },
  {
    id: 4,
    name: "Agricultural",
    value: 4,
    label: "Agricultural",
    group_types: [
      {
        name: "Agricultural Land",
        children: [
          {
            id: 51,
            name: "Agricultural Land",
          },
        ],
      },
    ],
  },
  {
    id: 5,
    name: "Unknown",
    value: 5,
    label: "Unknown",
    group_types: [
      {
        name: "Unknown",
        children: [
          {
            id: 52,
            name: "Unknown",
          },
        ],
      },
    ],
  },
];

export const currency = [
  { value: "MYR", label: "MYR" },
  { value: "USD", label: "USD" },
  { value: "AED", label: "AED" },
  { value: "AUD", label: "AUD" },
  { value: "CAD", label: "CAD" },
  { value: "CNY", label: "CNY" },
  { value: "EUR", label: "EUR" },
  { value: "GBP", label: "GBP" },
  { value: "INR", label: "INR" },
  { value: "JPY", label: "JPY" },
  { value: "KHR", label: "KHR" },
  { value: "PHP", label: "PHP" },
  { value: "SGD", label: "SGD" },
  { value: "THB", label: "THB" },
  { value: "TRY", label: "TRY" },
  { value: "VND", label: "VND" },
];

export const statusColor = [
  { id: 1, value: "Submitted", colorName: "Blue" },
  { id: 2, value: "Processing", colorName: "Cyan" },
  { id: 3, value: "Cancelled", colorName: "Red" },
  { id: 4, value: "Paid", colorName: "Lime" },
  { id: 5, value: "Completed", colorName: "Green" },
  { id: 6, value: "Rejected", colorName: "Red" },
  { id: 7, value: "Reviewing", colorName: "Fuchsia" },
  { id: 8, value: "Checking", colorName: "Yellow" },
];

export const edgePropDataMapping = [
  { edgeProp: "Penang", atlas: "Pulau Pinang" },
  { edgeProp: "Labuan", atlas: "Wilayah Persekutuan Lubuan" },
  { edgeProp: "Kuala Lumpur", atlas: "Wilayah Persekutuan Kuala Lumpur" },
  { edgeProp: "Putrajaya", atlas: "Wilayah Persekutuan Putrajaya" },
]